.group-photo {
	width: 100%;
	filter: brightness(80%);
	margin-top: 68px;
}

.section-title {
	margin-top: 40px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
	.group-photo {
		margin-top: 80px;
	}
}

@media (min-width: 1200px) {
}

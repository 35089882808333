.top-gap {
	margin-top: 68px;
}

.slick-track {
	display: flex !important;
	align-items: center;
}

.slick-list {
	background: #000000;
}

.slick-dots li button {
	height: 20px;
	width: 20px;
}

.slick-dots li button:before {
	font-size: 10px;
	color: #ffffff;
	opacity: .5;
	line-height: 2.5;
}

.slick-dots li.slick-active button:before {
	opacity: .9;
	color: #ffffff;
}

.slick-dots {
	height: 40px;
	bottom: 0;
	background: rgba(0, 0, 0, .3);
	display: block !important;
}

.slick-slide a {
	display: block !important;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
	.top-gap {
		margin-top: 80px;
	}
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

.msg-content {
	height: 100px !important;
	resize: none;
}

.btn {
	float: right;
	width: 100px;
}
.send-email:hover {
	cursor: pointer;
	opacity: 0.8;
}

.small-text {
	font-size: 11px;
}

@media (min-width: 768px) {
	.small-text {
		font-size: 80%;
	}
}

.home-overlay {
  height: 100vh;
  background-image: url("/home-background.jpg");
  background-size: cover;
  background-position: center;
}

.overlay-mask {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
}

.slick-track {
  display: flex !important;
  align-items: center;
}

.slick-list {
  background: #000000;
}

.slick-dots li button {
  height: 20px;
  width: 20px;
}

.slick-dots li button:before {
  font-size: 10px;
  color: #ffffff;
  opacity: .5;
  line-height: 2.5;
}

.slick-dots li.slick-active button:before {
  opacity: .9;
  color: #ffffff;
}

.slick-dots {
  height: 40px;
  bottom: 0;
  background: rgba(0, 0, 0, .3);
  display: none !important;
}

.slick-slide a {
  display: block !important;
}

.home-text {
  font-weight: bold;
  color: #fff;
  font-size: 9vw;
  letter-spacing: 3px;
}

.home-text-eng {
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  font-size: 2.5vw;
}

.section-wrapper {
  background: #fafafa;
}

.description-logo {
  max-width: 100%;
  max-height: 100%;
}

.description-text {
  font-size: 14px;
}

.description-gap {
  height: 20px;
}

.description {
  padding-top: 70px;
  padding-bottom: 70px;
}

.top-title {
  font-size: 21px;
}

@media (min-width: 576px) {
  .home-text {
    font-size: 7vw;
  }
  
  .home-text-eng {
    font-size: 2vw;
  }
}

@media (min-width: 768px) {
  .home-text {
    font-size: 5vw;
  }
  
  .home-text-eng {
    font-size: 1.8vw;
  }
  
  .slick-dots {
    display: block !important;
  }
}

@media (min-width: 992px) {
  .description-text {
    font-size: 18px;
  }

  .top-title {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .home-text {
    font-size: 4vw;
  }
  
  .home-text-eng {
    font-size: 1.4vw;
  }
}


@import url(https://fonts.googleapis.com/css?family=Ma+Shan+Zheng|Pacifico&display=swap);
@import url(https://fonts.googleapis.com/css?family=Noto+Sans+SC&display=swap);
div.back-to-top {
	position: fixed;
	right: 0;
	bottom: 0;
	margin: 15px;
	height: 45px;
	width: 45px;
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.3);
	color: #ffffff;
}

.nav-logo {
	width: 210px;
}

@media (min-width: 768px) {
	.nav-logo {
		width: 270px;
	}
}

.home-overlay {
  height: 100vh;
  background-image: url("/home-background.jpg");
  background-size: cover;
  background-position: center;
}

.overlay-mask {
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
}

.slick-track {
  display: flex !important;
  align-items: center;
}

.slick-list {
  background: #000000;
}

.slick-dots li button {
  height: 20px;
  width: 20px;
}

.slick-dots li button:before {
  font-size: 10px;
  color: #ffffff;
  opacity: .5;
  line-height: 2.5;
}

.slick-dots li.slick-active button:before {
  opacity: .9;
  color: #ffffff;
}

.slick-dots {
  height: 40px;
  bottom: 0;
  background: rgba(0, 0, 0, .3);
  display: none !important;
}

.slick-slide a {
  display: block !important;
}

.home-text {
  font-weight: bold;
  color: #fff;
  font-size: 9vw;
  letter-spacing: 3px;
}

.home-text-eng {
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
  font-size: 2.5vw;
}

.section-wrapper {
  background: #fafafa;
}

.description-logo {
  max-width: 100%;
  max-height: 100%;
}

.description-text {
  font-size: 14px;
}

.description-gap {
  height: 20px;
}

.description {
  padding-top: 70px;
  padding-bottom: 70px;
}

.top-title {
  font-size: 21px;
}

@media (min-width: 576px) {
  .home-text {
    font-size: 7vw;
  }
  
  .home-text-eng {
    font-size: 2vw;
  }
}

@media (min-width: 768px) {
  .home-text {
    font-size: 5vw;
  }
  
  .home-text-eng {
    font-size: 1.8vw;
  }
  
  .slick-dots {
    display: block !important;
  }
}

@media (min-width: 992px) {
  .description-text {
    font-size: 18px;
  }

  .top-title {
    font-size: 24px;
  }
}

@media (min-width: 1200px) {
  .home-text {
    font-size: 4vw;
  }
  
  .home-text-eng {
    font-size: 1.4vw;
  }
}


.card-pic {
  width: 100%;
}

.card-img {
  overflow: hidden;
}

.card {
  margin-top: 60px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}

.card:hover {
  cursor: pointer;
  /* transform: scale(1.01);
  -webkit-transform: scale(1.01);
  -moz-transform: scale(1.01);
  -ms-transform: scale(1.01);
  -o-transform: scale(1.01); */
}

.card-text {
  font-size: 14px;
  color: #545454;
}

.card-title {
  font-size: 25px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
  .card {
    margin-left: 70px;
    margin-right: 70px;
  }

  .card-title {
    font-size: 30px;
  }
}

@media (min-width: 992px) {
  .card {
    margin-left: 110px;
    margin-right: 110px;
  }

  .card-title {
    font-size: 35px;
  }

  .card-text {
    font-size: 16px;
  }
}

@media (min-width: 1200px) {
  .card-img {
    max-height: 1000px;
    overflow: hidden;
  }
}
.group-photo {
	width: 100%;
	-webkit-filter: brightness(80%);
	        filter: brightness(80%);
	margin-top: 68px;
}

.section-title {
	margin-top: 40px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
}

@media (min-width: 992px) {
	.group-photo {
		margin-top: 80px;
	}
}

@media (min-width: 1200px) {
}

.about-card-pic {
	height: auto;
	max-width: 70%;
}

.hover-default:hover {
	cursor: default;
}

.text-md-left {
	display: flex;
	justify-content: center;
}

.about-card-pic-wrapper {
	text-align: center;
}

.about-card-detail-position {
	font-size: 30px;
	font-weight: bold;
}

.about-card-detail-name {
	font-size: 22px;
	margin-bottom: 10px;
}

.about-card-detail-desc {
	font-size: 14px;
	color: #545454;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
	.about-card-pic-wrapper {
		display: block;
	}

	.about-card-pic {
		height: auto;
		max-width: 100%;
	}

	div.card {
		margin-left: 50px;
		margin-right: 50px;
	}

	.text-md-left {
		display: flex;
		justify-content: flex-start;
	}

	.about-card-detail-position {
		margin-top: 10px;
	}

	.about-card-detail-name {
		margin-top: 5px;
	}

	.about-card-detail-desc {
		margin-top: 15px;
	}
}

@media (min-width: 992px) {
	div.card {
		margin-left: 100px;
		margin-right: 100px;
	}

	.about-card-detail-position {
		margin-top: 10px;
		font-size: 35px;
	}

	.about-card-detail-name {
		font-size: 25px;
		margin-top: 5px;
	}

	.about-card-detail-desc {
		margin-top: 20px;
		font-size: 17px;
	}
}

@media (min-width: 1200px) {
}

.congrats-en {
	text-shadow: 5px 5px 4px #000000;
	background-image: url('/congrats-background.jpg');
	background-position: center;
	background-color: rgba(0, 0, 0, 0.2);
	background-size: cover;
	color: #ffffff;
	margin-top: 60px;
}

.congrats-en-text {
	font-family: 'Pacifico', cursive;
	font-size: 13vw;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.65);
	padding-top: 50px;
	padding-bottom: 50px;
}

.congrats-message {
	font-family: 'Ma Shan Zheng', cursive;
	background-color: #fffef5;
	background-image: url('/congrats-message-background.png');
	background-repeat: repeat;
	box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
	padding: 25px 15px 25px 15px;
}

.congrats-message-text {
	font-size: 20px;
	margin-bottom: 20px;
}

.booklet-cover {
	max-width: 90%;
	box-shadow: 0 5px 15px rgba(0, 0, 0, .65);
}

.booklet-cover:hover {
	box-shadow: 0 5px 15px rgba(0, 0, 0, .95);
}

@media (min-width: 576px) {
	.congrats-en-text {
		font-size: 11vw;
		padding-top: 70px;
		padding-bottom: 70px;
	}
}

@media (min-width: 768px) {
	.congrats-en-text {
		font-size: 10vw;
		padding-top: 80px;
		padding-bottom: 80px;
	}

	.congrats-message-text {
		font-size: 25px;
		margin-bottom: 30px;
	}

	.booklet-cover {
		max-width: 80%;
	}
}

@media (min-width: 992px) {
	.congrats-en-text {
		font-size: 9vw;
		padding-top: 90px;
		padding-bottom: 90px;
	}

	.congrats-message {
		margin-left: 30px;
		margin-right: 30px;
		padding: 30px 20px 30px 20px;
	}
}

@media (min-width: 1200px) {
	.congrats-en-text {
		font-size: 8vw;
		padding-top: 100px;
		padding-bottom: 100px;
	}
}

a.post-list-item {
	width: 100%;
	font-size: 16px;
	text-align: center;
	padding-top: 15px;
	padding-bottom: 15px;
}

a.list-group-item-action {
	color: #212529;
}

@media (min-width: 576px) {
	a.post-list-item {
		font-size: 17px;
	}
}

@media (min-width: 768px) {
	a.post-list-item {
		font-size: 18px;
	}
}

@media (min-width: 992px) {
	a.post-list-item {
		font-size: 23px;
	}

	ul.post-list-group {
		margin-left: 50px;
		margin-right: 50px;
	}
}

@media (min-width: 1200px) {
}

div.card {
	margin-top: 30px;
	margin-left: 0px;
	margin-right: 0px;
}

.event-pic-wrapper {
	max-height: 500px;
	overflow: hidden;
}

.event-pic {
	width: 100%;
}

.event-title {
	font-size: 28px;
	font-weight: 600;
}

.event-content {
	font-size: 13px;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
	.card {
		/* margin-left: 70px;
    margin-right: 70px; */
	}

	.event-content {
		font-size: 14px;
	}
}

@media (min-width: 992px) {
	.card {
		/* margin-left: 110px;
    margin-right: 110px; */
	}
}

@media (min-width: 1200px) {
	div.card {
		margin-left: 50px;
		margin-right: 50px;
	}

	.event-content {
		font-size: 15px;
	}
}

.stick-bot {
	position: absolute;
	bottom: 0;
}

@media (min-width: 768px) {
	.underline {
		width: 110px !important;
	}
}

.top-gap {
	margin-top: 68px;
}

.slick-track {
	display: flex !important;
	align-items: center;
}

.slick-list {
	background: #000000;
}

.slick-dots li button {
	height: 20px;
	width: 20px;
}

.slick-dots li button:before {
	font-size: 10px;
	color: #ffffff;
	opacity: .5;
	line-height: 2.5;
}

.slick-dots li.slick-active button:before {
	opacity: .9;
	color: #ffffff;
}

.slick-dots {
	height: 40px;
	bottom: 0;
	background: rgba(0, 0, 0, .3);
	display: block !important;
}

.slick-slide a {
	display: block !important;
}

@media (min-width: 576px) {
}

@media (min-width: 768px) {
	.top-gap {
		margin-top: 80px;
	}
}

@media (min-width: 992px) {
}

@media (min-width: 1200px) {
}

.form-wrapper {
	display: flex;
	align-items: flex-end;
}

.msg-content {
	height: 100px !important;
	resize: none;
}

.btn {
	float: right;
	width: 100px;
}
.send-email:hover {
	cursor: pointer;
	opacity: 0.8;
}

.small-text {
	font-size: 11px;
}

@media (min-width: 768px) {
	.small-text {
		font-size: 80%;
	}
}

.section-wrapper {
	background: #fafafa;
}

.copyright {
	font-size: 7px;
	margin-bottom: 7px;
}

.footer-logos {
	max-width: 100%;
}

.footer-text {
	font-size: 35px;
	font-weight: 600;
	text-shadow: 3.3px 3.3px 3px rgba(0, 0, 0, .2);
}

.underline {
	background: #212529;
	width: 80px;
	height: 3px;
	margin: auto;
	margin-top: 5px;
	margin-bottom: 30px;
	box-shadow: 3.3px 3.3px 3px rgba(0, 0, 0, .2);
}

.footer-qrcode {
	max-width: 120px;
	margin-bottom: 50px;
}

.follow-link:hover {
	transform: scale(1.08);
	-webkit-transform: scale(1.08);
	-moz-transform: scale(1.08);
	-ms-transform: scale(1.08);
	-o-transform: scale(1.08);
}

@media (min-width: 576px) {
	.footer-logos {
		max-width: 70%;
	}

	.copyright {
		font-size: 10px;
		margin-bottom: 10px;
	}

	.footer-qrcode {
		max-width: 130px;
	}
}

@media (min-width: 768px) {
	.footer-logos {
		max-width: 60%;
	}

	.footer-text {
		font-size: 45px;
	}

	.footer-qrcode {
		max-width: 150px;
	}
}

@media (min-width: 992px) {
	.footer-logos {
		max-width: 50%;
	}
}

@media (min-width: 1200px) {
	.copyright {
		font-size: 13px;
		margin-bottom: 13px;
	}

	.footer-logos {
		max-width: 40%;
	}
}

.admin-login {
	height: 100vh;
	width: 100vw;
	display: flex;
	justify-content: center;
	align-items: center;
}

.drag-handle {
	cursor: -webkit-grab;
	cursor: grab;
}

.sidebar {
	position: fixed;
	left: 0;
	margin-left: 10px;
	display: flex;
	flex-direction: column;
}

* {
	font-family: 'Noto Sans SC', sans-serif;
}

.ch-text {
	letter-spacing: 1.5px;
}


@import url('https://fonts.googleapis.com/css?family=Ma+Shan+Zheng|Pacifico&display=swap');

.congrats-en {
	text-shadow: 5px 5px 4px #000000;
	background-image: url('/congrats-background.jpg');
	background-position: center;
	background-color: rgba(0, 0, 0, 0.2);
	background-size: cover;
	color: #ffffff;
	margin-top: 60px;
}

.congrats-en-text {
	font-family: 'Pacifico', cursive;
	font-size: 13vw;
	text-align: center;
	background-color: rgba(0, 0, 0, 0.65);
	padding-top: 50px;
	padding-bottom: 50px;
}

.congrats-message {
	font-family: 'Ma Shan Zheng', cursive;
	background-color: #fffef5;
	background-image: url('/congrats-message-background.png');
	background-repeat: repeat;
	box-shadow: 0 5px 10px rgba(0, 0, 0, .1);
	padding: 25px 15px 25px 15px;
}

.congrats-message-text {
	font-size: 20px;
	margin-bottom: 20px;
}

.booklet-cover {
	max-width: 90%;
	box-shadow: 0 5px 15px rgba(0, 0, 0, .65);
}

.booklet-cover:hover {
	box-shadow: 0 5px 15px rgba(0, 0, 0, .95);
}

@media (min-width: 576px) {
	.congrats-en-text {
		font-size: 11vw;
		padding-top: 70px;
		padding-bottom: 70px;
	}
}

@media (min-width: 768px) {
	.congrats-en-text {
		font-size: 10vw;
		padding-top: 80px;
		padding-bottom: 80px;
	}

	.congrats-message-text {
		font-size: 25px;
		margin-bottom: 30px;
	}

	.booklet-cover {
		max-width: 80%;
	}
}

@media (min-width: 992px) {
	.congrats-en-text {
		font-size: 9vw;
		padding-top: 90px;
		padding-bottom: 90px;
	}

	.congrats-message {
		margin-left: 30px;
		margin-right: 30px;
		padding: 30px 20px 30px 20px;
	}
}

@media (min-width: 1200px) {
	.congrats-en-text {
		font-size: 8vw;
		padding-top: 100px;
		padding-bottom: 100px;
	}
}
